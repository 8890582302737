<template>
  <div class="manage-subcription">
    <c-heading
      :fontSize="{ base: 'sm', lg: '2xl', xl: '4xl' }"
      as="h1"
      display="flex"
      alignItems="baseline"
      mb="4"
    >
      <c-text mr="3"> Change Plan </c-text>
    </c-heading>

    <c-box mb="8" d="flex" justify-content="space-between">
      <c-box d="flex" align-items="center">
        <c-text fontSize=".8em">Your current plan is</c-text>
        <div>
          <c-text
            rounded="md"
            ml="2"
            px="3"
            py="1"
            bg="#ef5323"
            color="white"
            fontSize=".6em"
            >{{ getCurrentPlan.name }}
          </c-text>
        </div>
      </c-box>

      <c-button
        @click="$router.push({ name: 'Subscription' })"
        size="md"
        px="8"
        variant-color="vc-orange"
        variant="outline"
      >
        Plans
      </c-button>
    </c-box>
    <div class="d">
      <div class="plan">
        <c-pseudo-box
          v-for="(plan, ix) in plans"
          :key="ix"
          px="4"
          py="3"
          mb="3"
          w="100%"
          :bg="selectedPlan.title === plan.title ? '#F4F6FC' : 'white'"
          box-shadow="md"
          rounded="md"
          @click="selectedPlan = plan"
          v-chakra
          :_hover="{
            border: '1px',
            borderColor: '#ef5323',
            cursor: 'pointer',
          }"
        >
          <c-text
            d="flex"
            align-items="center"
            color="#ef5323"
            fontSize="xs"
            mb="2"
            >{{ plan.title }}
          </c-text>
          <c-text fontWeight="bold" mb="3" fontSize="md">{{
            plan.description
          }}</c-text>
          <c-text fontSize="xs" mb="4">{{ plan.stage }}</c-text>
          <c-text fontWeight="bold" fontSize="md"
            >${{ plan.monthlyPrice.price }}</c-text
          >
          <c-text fontSize="xs" mb="1">{{ plan.frequency }}</c-text>
        </c-pseudo-box>
      </div>
      <div class="plan_details">
        <c-grid-item>
          <c-box mb="4">
            <c-text px="2" py="3" mb="1" fontSize="md" fontWeight="bold">
              Payment Information</c-text
            >
            <div style="text-align: center" v-show="isLoadingCards">
              <c-spinner color="#EF5323" />
            </div>

            <c-grid
              w="100%"
              px="2"
              template-columns="repeat(12, 1fr)"
              gap="4"
              v-show="!isLoadingCards"
            >
              <c-grid-item col-span="4">
                <c-box>
                  <label for>Card Details</label>
                  <div id="cc" ref="cad"></div>
                </c-box>
              </c-grid-item>
              <c-grid-item v-if="!noActiveCard" col-span="5">
                <c-box>
                  <label>.</label>
                  <div>
                    <c-button
                      @click="submitPaymentMethod"
                      size="md"
                      px="8"
                      variant-color="vc-orange"
                      variant="outline"
                    >
                      Save New Card
                      <c-text ml="1" fontSize="1.5em" color="#ef5323">+</c-text>
                    </c-button>
                  </div>
                </c-box>
              </c-grid-item>

              <c-grid-item v-if="noActiveCard" col-span="4">
                <c-box>
                  <label for>Full Name</label>
                  <c-input
                    v-model="form.name"
                    placeholder="Enter Full Name"
                    type="text"
                  />
                </c-box>
              </c-grid-item>
              <c-grid-item v-if="noActiveCard" col-span="4">
                <c-box>
                  <label for>Email Address</label>
                  <c-input
                    v-model="form.email"
                    placeholder="Enter Email Address"
                    type="email"
                  />
                </c-box>
              </c-grid-item>

              <c-grid-item v-if="noActiveCard" col-span="4">
                <c-box>
                  <label for>Phone Number</label>
                  <c-input
                    v-model="form.phone"
                    placeholder="Enter Phone Number"
                    type="number"
                  />
                </c-box>
              </c-grid-item>

              <!-- <c-grid-item v-if="noActiveCard" col-span="4">
                <c-box>
                  <c-box>
                    <label for>Coupon</label>
                    <c-input
                      v-model="form.coupon"
                      placeholder="Enter Coupon"
                      type="text"
                    />
                  </c-box>
                </c-box>
              </c-grid-item> -->

              <c-grid-item v-if="noActiveCard" col-span="4">
                <c-box>
                  <c-box>
                    <c-button
                      @click="saveCustomer"
                      size="md"
                      px="8"
                      mt="5"
                      :isLoading="saveBtnLoading"
                      variant-color="vc-orange"
                      :disable="noActiveCard"
                    >
                      Save
                    </c-button>
                  </c-box>
                </c-box>
              </c-grid-item>
            </c-grid>
          </c-box>
          <c-box>
            <c-box
              d="flex"
              flex-direction="column"
              mb="3"
              w="100%"
              box-shadow="md"
              rounded="md"
            >
              <c-text px="4" py="3" fontSize="md" fontWeight="bold">
                Billing Information</c-text
              >

              <c-box mx="4" mb="4" mt="2" border="1px" borderColor="gray.200">
                <c-box px="5" py="3" v-if="!noActiveCard">
                  <c-text
                    d="flex"
                    align-items="center"
                    color="#ef5323"
                    fontSize="xs"
                    mb="2"
                    >{{ activeSub.name }}
                  </c-text>
                  <c-text mb="3" fontSize="md" fontWeight="bold">{{
                    activeSub.description
                  }}</c-text>

                  <c-text
                    mb="3"
                    fontSize=".8em"
                    color="gray.500"
                    v-if="activeSub.name !== 'Free'"
                  >
                    Next payment is due on {{ activeSub.nextBillingDate }} ${{
                      activeSub.price
                    }}
                    USD
                    {{ activeSub.frequency }}
                  </c-text>

                  <c-box>
                    <c-text mb="3" fontSize="sm" fontWeight="semibold"
                      >Payment card</c-text
                    >
                    <c-box
                      display="flex"
                      rounded="lg"
                      align-items="center"
                      my="4"
                      w="50%"
                      bg="#F4F6FC"
                    >
                      <c-box
                        backgroundSize="cover"
                        bgRepeat="no-repeat"
                        backgroundPosition="center center"
                        rounded="md"
                        h="100%"
                        mr="2"
                        py="2"
                        pl="2"
                        w="3em"
                      >
                        <img
                          v-if="activeCard.brand === 'visa'"
                          class="pl"
                          v-chakra
                          w="90%"
                          src="@/assets/img/visa-img.png"
                          alt="Card Type - Visa"
                        />
                        <img
                          v-else
                          class=""
                          v-chakra
                          w="90%"
                          src="@/assets/img/mastercard-img.png"
                          alt="Card Type - Mastercard"
                        />
                      </c-box>
                      <c-text
                        diplay="flex"
                        align-items="center"
                        justify="center"
                        color="gray.500"
                      >
                        **** {{ activeCard.last4 }}
                      </c-text>
                    </c-box>
                  </c-box>

                  <c-grid mb="3" template-columns="repeat(12, 1fr)" gap="6">
                    <c-grid-item col-span="7" v-if="activeSub.name !== 'Free'">
                      <c-button
                        @click="onEndPlanClick"
                        size="md"
                        px="8"
                        w="100%"
                        variant-color="vc-orange"
                        variant="outline"
                      >
                        End plan
                      </c-button>
                    </c-grid-item>
                    <c-grid-item col-span="3">
                      <c-button
                        @click="showCards = !showCards"
                        size="md"
                        px="8"
                        w="100%"
                        variant-color="vc-orange"
                        variant="outline"
                      >
                        Change card
                        <svg v-chakra fill="#ef5323" w="6" h="6" ml="1">
                          <use
                            href="@/assets/icons/icon-refresh.svg#refresh"
                          ></use>
                        </svg>
                      </c-button>
                    </c-grid-item>
                  </c-grid>
                </c-box>

                <span v-else>You have no Plan or Bills</span>

                <c-box bg="#F4F6FC" mt="3" px="5" py="3" v-if="showCards">
                  <c-grid mb="3" template-columns="repeat(5, 1fr)" gap="6">
                    <c-grid-item col-span="3">
                      <c-text mb="3" fontSize="md" fontWeight="semibold"
                        >Recent Cards</c-text
                      >

                      <c-box
                        w="100%"
                        bg="white"
                        d="flex"
                        align-items="center"
                        justify-content="space-between"
                        h="3em"
                        py="2"
                        px="3"
                        mb="3"
                        rounded="md"
                        v-for="(card, index) in cards"
                        :key="index"
                      >
                        <c-box d="flex" align-items="center">
                          <div v-if="card.IsActive" d="flex">
                            <div
                              class="active"
                              @click="defaultCard(card)"
                            ></div>
                          </div>
                          <c-text v-else>
                            <div
                              class="not_active"
                              @click="defaultCard(card)"
                            ></div>
                          </c-text>

                          <c-box
                            backgroundSize="cover"
                            bgRepeat="no-repeat"
                            backgroundPosition="center center"
                            rounded="md"
                            h="100%"
                            mr="2"
                            w="3em"
                          >
                            <img
                              v-if="card.brand === 'visa'"
                              class=""
                              v-chakra
                              w="90%"
                              src="@/assets/img/visa-img.png"
                              alt="Card Type - Visa"
                            />
                            <img
                              v-else
                              class=""
                              v-chakra
                              w="90%"
                              src="@/assets/img/mastercard-img.png"
                              alt="Card Type - Mastercard"
                            />
                          </c-box>

                          <c-text>**** {{ card.last4 }}</c-text>
                        </c-box>

                        <svg
                          @click="deleteCard(card)"
                          fill="vc-orange.300"
                          v-chakra="{
                            cursor: 'pointer',
                            width: '12px',
                            height: '12px',
                          }"
                        >
                          <use
                            href="@/assets/icons/minus-box-fill.svg#minus"
                          ></use>
                        </svg>
                      </c-box>

                      <c-text
                        font-size=".7em"
                        v-if="cards.length === 0 && !isLoadingCards"
                        >No cards added yet</c-text
                      >
                      <ContentLoader
                        v-if="isLoadingCards"
                        viewBox="0 0 250 130"
                        primaryColor="#ddd"
                      >
                        <rect
                          x="0"
                          y="0"
                          rx="3"
                          ry="3"
                          width="150"
                          height="10"
                        />
                      </ContentLoader>
                    </c-grid-item>
                  </c-grid>
                </c-box>
              </c-box>
            </c-box>
            <c-box bg="white" mb="3" w="100%" box-shadow="md" rounded="md">
              <c-box d="flex" justify-content="space-between">
                <c-text px="4" py="3" mb="1" fontSize="md" fontWeight="bold">
                  Billing Summary</c-text
                >

                <c-box d="flex" align-items="center">
                  <c-text mr="2" fontSize="xs" d="flex" color="black"
                    >Billing Cycle: Monthly
                  </c-text>
                  <label
                    :for="`toggle-billing-checked-${selectedPlan.title}`"
                    class="toggle-button"
                  >
                    <input
                      type="checkbox"
                      :id="`toggle-billing-checked-${selectedPlan.title}`"
                      :checked="!selectedPlan.isMonthly"
                      @change="selectedPlan.isMonthly = !selectedPlan.isMonthly"
                    />
                    <div class="toggle-bg"></div>
                    <c-text mx="2" fontSize="xs" d="flex" color="#ef5323"
                      >Yearly
                    </c-text>
                  </label>
                </c-box>
              </c-box>
              <c-box px="4" py="3" borderTop="1px" borderColor="gray.200">
                <c-grid w="100%" template-columns="repeat(2, 1fr)" gap="1">
                  <c-box fontSize="sm">
                    <c-text color="black" fontWeight="500"
                      >1x {{ selectedPlan.title }}
                      {{ selectedPlan.isMonthly ? 'monthly' : 'annual' }}
                      plan</c-text
                    >
                  </c-box>

                  <c-box textAlign="right" h="10">
                    <c-text fontSize="sm" color="black" fontWeight="500"
                      >${{
                        selectedPlan.isMonthly
                          ? selectedPlan.monthlyPrice.price
                          : selectedPlan.yearlyPrice.price
                      }}</c-text
                    >
                  </c-box>
                  <c-box fontSize="sm">
                    <c-text fontSize="sm" color="black" fontWeight="500"
                      >Sales tax</c-text
                    >
                  </c-box>
                  <c-box textAlign="right" h="10">
                    <c-text fontSize="sm" color="gray.400">$0.00</c-text>
                  </c-box>
                  <c-box fontSize="sm">
                    <c-text fontSize="sm" color="black" fontWeight="500"
                      >Coupon</c-text
                    >
                  </c-box>
                  <c-box textAlign="right" h="10">
                    <c-input
                      v-model="coupon"
                      placeholder="Enter Coupon"
                      type="text"
                    />
                  </c-box>
                </c-grid>
              </c-box>
              <c-box px="4" py="3" borderTop="1px" borderColor="gray.200">
                <c-grid w="100%" template-columns="repeat(2, 1fr)" gap="6">
                  <c-box fontSize="sm">
                    <c-text fontSize="sm" color="black" fontWeight="500"
                      >Total</c-text
                    >
                  </c-box>

                  <c-box textAlign="right" h="10">
                    <c-text fontSize="sm" color="black" fontWeight="500"
                      >${{ totalCost }}</c-text
                    >
                  </c-box>
                </c-grid>
              </c-box>
              <c-box px="4" py="3">
                <c-box mb="3">
                  <c-checkbox v-model="agreeToPlan" fontSize="xs"
                    >I agree to the Terms of Service and acknowledge the Privacy
                    Policy</c-checkbox
                  >
                </c-box>
                <c-button
                  :disabled="!agreeToPlan"
                  @click="subscribeToPlan"
                  size="md"
                  px="8"
                  variant-color="vc-orange"
                  :isLoading="btnLoading"
                >
                  Start {{ selectedPlan.title }}
                </c-button>
              </c-box>
            </c-box>

            <c-box mb="3" w="100%" box-shadow="md" rounded="md">
              <c-text px="4" py="3" mb="3" fontSize="md" fontWeight="bold">
                Billing history</c-text
              >
              <div style="text-align: center" v-if="isLoadingHistory">
                <c-spinner color="#EF5323" />
              </div>
              <div v-else>
                <c-box
                  px="4"
                  py="3"
                  borderTop="1px"
                  borderColor="gray.200"
                  v-for="bill in history"
                  :key="bill.id"
                >
                  <c-grid w="100%" template-columns="repeat(3, 1fr)" gap="6">
                    <c-box h="10" fontSize="sm">{{
                      formatDate(bill.dateCharged)
                    }}</c-box>
                    <c-box h="10">
                      <c-text fontSize="sm">{{ bill.plan }}</c-text>
                      <c-text color="blue.400" fontSize="xs">{{
                        bill.actualDuration
                      }}</c-text>
                    </c-box>
                    <c-box textAlign="right" h="10">
                      <c-text fontSize="sm"
                        >Billed: ${{ bill.actualPrice }}</c-text
                      >
                      <c-text color="blue.400" fontSize="xs">Invoice</c-text>
                    </c-box>
                  </c-grid>
                </c-box>
              </div>
            </c-box>
          </c-box>
        </c-grid-item>
      </div>
    </div>

    <c-modal :is-open="showSubscribeModal" :closeOnOverlayClick="false">
      <c-modal-content ref="content">
        <c-modal-header>Thank you for subscribing</c-modal-header>
        <c-modal-body>
          <c-box display="flex" align-items="center" justify-content="center">
            <p>
              You have successfully subscribed to our
              {{ selectedPlan.title }} plan. Get started now to enjoy our
              premium services.
            </p>
          </c-box>
        </c-modal-body>
        <c-modal-footer>
          <c-button variant-color="vc-orange" mr="3" @click="gotoApp">
            Start {{ selectedPlan.title }} Plan
          </c-button>
        </c-modal-footer>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
// import { StripeElementPayment } from '@vue-stripe/vue-stripe';
import {
  addCard,
  getCards,
  deleteCard,
  defaultCard,
  createCustomer,
  createSubscription,
  cancelSubscription,
  getClientSecret,
  getBillingHistory,
} from '../../../services/subscription';
import { ContentLoader } from 'vue-content-loader';
import { loadStripe } from '@stripe/stripe-js';
import subscribtionMixin from '@/mixins/subscribtion.js';

export default {
  mixins: [subscribtionMixin],
  components: {
    ContentLoader,
  },
  data() {
    return {
      btnLoading: false,
      showSubscribeModal: false,
      history: [],
      selectedHokage: '2',
      token: null,
      card: '',
      agreeToPlan: false,
      isAddingCard: false,
      hasASubscription: false,
      publishableKey:
        'pk_live_51L5BIlDpNBuvEcjKlqBJj9MtxRFmrdTUEF4sfQBNmutEblVo6W0pIoGFa8Y6G9cAhM6NBUX6f27iPdbRrqETTAWm00fR2M3JiX',
      elementsOptions: {
        appearance: {}, // appearance options
        clientSecret: '',
      },
      addedCustomer: false,
      saveBtnLoading: false,
      showCards: false,
      isEndPlanOpen: false,
      isLoadingCards: true,
      isLoadingHistory: true,
      selectedPlan: {},
      activeSub: {},
      coupon: '',
      form: {
        name: '',
        phone: '',
        email: '',
      },
      stripe: '',
      plans: [
        {
          title: 'Pro',
          description: 'From Validation to Commercialization',
          stage:
            'Stage: Validation (Market research and product-market fit), Operational',
          price: '$12',
          frequency: 'per month',
          isMonthly: true,
          monthlyPrice: {},
          yearlyPrice: {},
        },
        {
          title: 'Premium',
          description: 'From Operational to Scaling',
          stage: 'Stage: Scaling, fully established',
          price: '$30',
          frequency: 'per month',
          isMonthly: true,
          monthlyPrice: {},
          yearlyPrice: {},
        },
      ],
      cards: [],
      activeCard: [],
    };
  },
  mounted() {
    this.generatePaymentIntent();
    this.getPlans();
    this.example();
    this.getCards();
    this.getBillingHistory();
    this.activeSub = this.getActiveSubscription();
  },
  computed: {
    ...mapGetters('subscription', ['getCurrentPlan']),
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    ...mapState('subscription', {
      storePlans: (state) => state.plans,
    }),
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    totalCost() {
      return this.selectedPlan.isMonthly
        ? this.selectedPlan.monthlyPrice.price
        : this.selectedPlan.yearlyPrice.price;
    },
    noActiveCard() {
      return this.cards.length === 0;
    },
  },
  methods: {
    async generatePaymentIntent() {
      const cs = await getClientSecret();
      this.elementsOptions.clientSecret = cs.data.data.clientSecret;
    },
    async example() {
      let strip = await loadStripe(this.publishableKey);
      this.stripe = strip;
      let elements = strip.elements({
        fonts: [
          {
            cssSrc:
              'https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600',
          },
        ],
      });
      this.card = elements.create('card', {
        hidePostalCode: true,
        style: {
          base: {
            lineHeight: '40px',
            fontSize: '1.1rem',
            fontFamily: '"Montserrat",sans-serif',
            color: '#1A202C',

            '::placeholder': {
              color: '#A0AEC0',
            },
          },
        },
      });
      this.card.mount(this.$refs.cad);
    },
    getCards() {
      this.isLoadingCards = true;
      getCards()
        .then((res) => {
          this.cards = res.data.data;

          this.activeCard = this.cards.find((card) => card.IsActive);
        })

        .finally(() => {
          this.isLoadingCards = false;
        });

      this.isLoadingCards = false;
    },
    getBillingHistory() {
      getBillingHistory(this.activeCompany.id.toString())
        .then((res) => {
          this.isLoadingHistory = false;
          this.history = res.data.billing_history;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoadingHistory = false;
        });
    },
    submitPaymentMethod() {
      this.stripe
        .confirmCardSetup(this.elementsOptions.clientSecret, {
          payment_method: {
            card: this.card,
            billing_details: {
              email: this.form.email,
            },
          },
        })

        .then((res) => {
          // this.btnLoading = false;
          console.log(res);
          if (res.error) {
            this.$toast({
              title: 'An error occurred.',
              description: `Error adding billing card. ${res.error.message}`,
              status: 'error',
              position: 'top',
              duration: 6000,
            });
          } else {
            this.getCards();
            this.$toast({
              title: 'Success.',
              description: `New billing card added successfully.`,
              status: 'success',
              position: 'top',
              duration: 6000,
            });
          }
        })
        .catch((err) => {
          // this.btnLoading = false;
          console.log(err);
          this.$toast({
            title: 'An error occurred..',
            description: `Error trying to bill card.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });

      // this.btnLoading = false;
    },
    onEndPlanClick() {
      this.isEndPlanOpen = !this.isEndPlanOpen;
      cancelSubscription();
      this.$toast({
        title: 'Success!!!',
        description: `Subscription renewal has been cancelled successfully`,
        status: 'success',
        position: 'top',
        duration: 3000,
      });
    },
    handleAddCard() {
      this.isAddingCard = true;
      this.$refs.elementRef.submit();
      // createCustomer({
      //   email: this.user.email,
      //   name: `${this.user.firstname} ${this.user.lastname}`,
      // }).then((res) => {
      //   console.log(res);
      // });
    },
    async subscribeToPlan() {
      this.btnLoading = true;

      // await this.submitPaymentMethod();

      // await new Promise((resolve) => setTimeout(resolve, 5000));

      // this.createPlanSubscription();

      if (this.addedCustomer || !this.noActiveCard) {
        this.createPlanSubscription();
      }

      if (!this.addedCustomer && this.noActiveCard) {
        await this.saveCustomer();

        // wait for 5 seconds
        await new Promise((resolve) => setTimeout(resolve, 4000));

        await this.createPlanSubscription();
      }
    },
    createPlanSubscription() {
      createSubscription({
        // coupon: this.form.coupon,
        priceId: this.selectedPlan.isMonthly
          ? this.selectedPlan.monthlyPrice.stripeId
          : this.selectedPlan.yearlyPrice.stripeId,
        // parentCompanyId: this.activeCompany.id,
        invoice_timestamp: '1674846890',
        coupon: this.form.coupon,
      })
        .then((res) => {
          console.log(res);
          this.$toast({
            title: 'Success',
            description: `Subscription created successfully`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
          this.btnLoading = false;
          this.showSubscribeModal = true;
        })
        .catch((error) => {
          const err = Object.assign({}, error);
          const { message } = err.response.data;

          this.btnLoading = false;
          this.$toast({
            title: 'An error occurred.',
            description: `${message}`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    saveCustomer() {
      this.saveBtnLoading = true;
      createCustomer({
        email: this.form.email,
        name: this.form.name,
        phone: this.form.phone,
      })
        .then(async () => {
          this.addedCustomer = true;
          // this.$refs.elementRef.submit();
          await this.generatePaymentIntent();
          await new Promise((resolve) => setTimeout(resolve, 3000));

          await this.submitPaymentMethod();
          this.saveBtnLoading = false;
          this.$toast({
            title: 'Saved.',
            description: `Customer account created successfully.`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
        })
        .catch(async (error) => {
          this.saveBtnLoading = false;
          console.log(error);

          if (
            error.response.data.message ==
            'Stripe account already created for company'
          ) {
            await this.generatePaymentIntent();
            await this.submitPaymentMethod();
          } else {
            this.$toast({
              title: 'An error occurred.',
              description: `${error.response.data.message}`,
              status: 'error',
              position: 'top',
              duration: 3000,
            });
          }
        });
    },
    deleteCard(card) {
      const cardIndex = this.cards.indexOf(card);
      deleteCard({ cardPaymentId: card.cardId })
        .then(() => {
          this.$toast({
            title: 'Success!!!',
            description: `Card has been deleted`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
          this.cards.splice(cardIndex, 1);
        })
        .catch(() => {
          this.$toast({
            title: 'An error occurred.',
            description: `Can not remove default card.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    defaultCard(card) {
      // const cardIndex = this.cards.indexOf(card);
      // this.cards.splice(cardIndex, 1);
      this.isLoadingCards = true;
      defaultCard({ cardPaymentId: card.cardId })
        .then((res) => {
          this.$toast({
            title: 'Success!!!',
            description: `Billing card changed successfully`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
          console.log(res.data);
          this.getCards();
        })
        .catch(() => {
          // this.cards.splice(cardIndex, 0, card);
          this.$toast({
            title: 'An error occurred.',
            description: `Please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    addCard(token) {
      const set = {
        expiredDate: `${token.card.exp_year}-${token.card.exp_month}-01`,
        name: token.card.name || '',
        last4: token.card.last4,
        bin: token.card.bin || '',
        token: token.id,
        userId: this.user.id,
      };

      addCard(set)
        .then(() => {
          this.$toast({
            title: 'Success!!!',
            description: `Card has been added`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
        })
        .catch(() => {
          this.$toast({
            title: 'An error occurred.',
            description: `Could not add card, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
          // this.isAddingCard = false;
        })
        .finally(() => {
          this.isAddingCard = false;
        });
    },
    tokenCreated(token) {
      this.isAddingCard = true;
      this.addCard(token);
      console.log(token);
    },
    getPlans() {
      // console.log('right here', this.storePlans);
      const selectedPlan = this.$route.query.plan;
      const selectedFrequency = this.$route.query.frequency;
      if (selectedPlan) {
        this.selectedPlan = this.plans.find(
          (plan) => plan.title === selectedPlan
        );

        if (selectedFrequency !== 'MONTHLY')
          this.selectedPlan.isMonthly = false;
      }
      // const freePlan = this.storePlans.find((_item) => _item.name === 'Free');
      const premiumPlan = this.storePlans.find(
        (_item) => _item.name === 'Premium'
      );
      const proPlan = this.storePlans.find((_item) => _item.name === 'Pro');

      this.plans[0].monthlyPrice = proPlan?.plan_prices?.find(
        (price) => price.frequency === 'MONTHLY'
      );
      this.plans[0].yearlyPrice = proPlan?.plan_prices?.find(
        (price) => price.frequency === 'YEARLY'
      );

      this.plans[1].monthlyPrice = premiumPlan?.plan_prices?.find(
        (price) => price.frequency === 'MONTHLY'
      );
      this.plans[1].yearlyPrice = premiumPlan?.plan_prices?.find(
        (price) => price.frequency === 'YEARLY'
      );
    },
    gotoApp() {
      this.$router.push({
        path: `/app/`,
      });
    },
    formatDate(date) {
      return date && this.$moment(date).isValid()
        ? this.$moment(date).format('YYYY-MM-DD')
        : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle-button {
  @apply flex items-center cursor-pointer relative;

  .toggle-bg:after {
    content: '';
    @apply absolute top-0.5 left-0.5 bg-white border border-gray-300 rounded-full h-4 w-5 transition shadow-sm;
  }

  input:checked + .toggle-bg:after {
    transform: translateX(100%);
    @apply border-white;
  }

  input:checked + .toggle-bg {
    @apply bg-secondary border-secondary;
  }

  input {
    @apply sr-only;
  }

  div {
    @apply bg-gray-200 border-2 border-gray-200 h-4 w-10 rounded-full;
  }
}

label:not(.toggle-button) {
  font-size: 0.8em;
}
.d {
  @apply grid grid-cols-12 gap-3;
}
.plan {
  @apply col-span-4;
}
.plan_details {
  @apply col-span-8;
}
.not_active {
  @apply h-4 w-4 mx-2 rounded-full border border-secondary cursor-pointer hover:shadow-xl;
}
.active {
  @apply h-4 w-4 mx-2 rounded-full border cursor-pointer  hover:shadow-xl;
  background-color: green;
  border-color: green;
}
#cc {
  @apply border border-solid bg-white px-4  rounded-md;
}

.css-kfzfjk {
  border-radius: 10px;
}
</style>
