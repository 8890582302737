import { render, staticRenderFns } from "./Manage.vue?vue&type=template&id=03595ab5&scoped=true"
import script from "./Manage.vue?vue&type=script&lang=js"
export * from "./Manage.vue?vue&type=script&lang=js"
import style0 from "./Manage.vue?vue&type=style&index=0&id=03595ab5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03595ab5",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CHeading: require('@chakra-ui/vue').CHeading, CBox: require('@chakra-ui/vue').CBox, CButton: require('@chakra-ui/vue').CButton, CPseudoBox: require('@chakra-ui/vue').CPseudoBox, CSpinner: require('@chakra-ui/vue').CSpinner, CGridItem: require('@chakra-ui/vue').CGridItem, CInput: require('@chakra-ui/vue').CInput, CGrid: require('@chakra-ui/vue').CGrid, CCheckbox: require('@chakra-ui/vue').CCheckbox, CModalHeader: require('@chakra-ui/vue').CModalHeader, CModalBody: require('@chakra-ui/vue').CModalBody, CModalFooter: require('@chakra-ui/vue').CModalFooter, CModalContent: require('@chakra-ui/vue').CModalContent, CModalOverlay: require('@chakra-ui/vue').CModalOverlay, CModal: require('@chakra-ui/vue').CModal})
