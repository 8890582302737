var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-box',{attrs:{"py":"8","px":{ base: '4', lg: '0' }}},[_c('c-flex',{attrs:{"align":"center","justify":"space-between","mb":"8"}},[_c('c-flex',{attrs:{"align":"baseline"}},[_c('c-heading',{attrs:{"fontSize":"3xl","fontWeight":"700","mr":"3"}},[_vm._v(" Status ")])],1),_c('Notification')],1),_c('c-flex',{attrs:{"boxShadow":"0 3px 6px rgba(186, 186, 186, 0.16)","w":"100%","p":"8","bg":"#fff","borderRadius":"8px","justify":"space-between"}},[_c('c-box',{attrs:{"w":{ base: '100%', lg: '60%', sm: '100%', xs: '100%' },"mr":{ base: '0', lg: '6', sm: '0', xs: '0' }}},[_c('c-heading',{attrs:{"as":"h3","display":"flex","fontSize":"xl","mb":"3"}},[_c('c-text',{attrs:{"fontWeight":"normal","mr":"2"}},[_vm._v(" Level of Business: ")]),_c('c-text',{attrs:{"fontWeight":"600"}},[_vm._v(_vm._s(_vm.companyLevel))])],1),_c('c-flex',{attrs:{"align":"center","h":"80px"}},[_c('ProgressBar',{directives:[{name:"chakra",rawName:"v-chakra",value:({
            flex: 1,
          }),expression:"{\n            flex: 1,\n          }"}],attrs:{"value":_vm.companyLevelPercentage,"height":'20px'}}),_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
            w: '10',
            h: '10',
            m: '-5px',
            zIndex: '1',
          }),expression:"{\n            w: '10',\n            h: '10',\n            m: '-5px',\n            zIndex: '1',\n          }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/icon-target.svg") + "#target"}})])],1)],1)],1),_c('c-flex',{attrs:{"direction":{ lg: 'row', xs: 'column', xl: 'row' },"w":"100%","my":"8","justify":"space-between"}},[_c('c-box',{attrs:{"w":{ lg: '55%', xs: '100%' }}},[_c('Responsibilities')],1),_c('c-box',{attrs:{"w":{ lg: '45%', xs: '100%' }}},[_c('Feed')],1)],1),_c('LastTour'),_c('Milestones')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }