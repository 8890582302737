<template>
  <div class="subcription">
    <c-heading
      :fontSize="{ base: 'sm', lg: '2xl', xl: '4xl' }"
      as="h1"
      display="flex"
      alignItems="baseline"
      mb="4"
    >
      <c-text mr="3"> Choose plan to continue </c-text>
    </c-heading>

    <c-box
      d="flex"
      justify-content="flex-end"
      @click="showCurrentPlanDetails = true"
    >
      <c-button size="md" px="8" variant-color="vc-orange" variant="outline">
        View Subscription
      </c-button>
    </c-box>

    <c-box
      d="flex"
      :flex-direction="{ base: 'row', md: 'row', sm: 'column', xs: 'column' }"
      class="pricing"
      mb="10"
    >
      <c-box
        class="pricing__item"
        v-for="(plan, ix) in plans"
        :key="ix"
        :w="{ base: `${100 / 3}%`, md: `${100 / 3}%`, sm: '100%', xs: '100%' }"
        :mx="{
          base: ix === 1 ? '8' : '',
          md: ix === 1 ? '8' : '',
          sm: ' ',
          xs: ' ',
        }"
        py="4"
        boxShadow="lg"
      >
        <c-box
          px="5"
          class="item-header"
          borderBottom="1px"
          borderBottomColor="gray.200"
        >
          <c-text
            d="flex"
            align-items="center"
            color="#ef5323"
            fontSize="sm"
            mb="2"
            >{{ plan.title }}
            <c-text
              v-if="isCurrentPlan(plan)"
              ml="3"
              color="black"
              bg="gray.200"
              px="3"
              py="1"
              fontSize="xs"
              rounded="md"
              >Current Personal Plan</c-text
            >
          </c-text>
          <c-text fontWeight="bold" mb="3" fontSize="md">{{
            plan.description
          }}</c-text>
          <c-text fontSize="sm" mb="6">{{ plan.stage }}</c-text>
          <c-text fontWeight="bold" fontSize="md"
            >${{
              plan.isMonthly ? plan.monthlyPrice.price : plan.yearlyPrice.price
            }}</c-text
          >
          <c-text fontSize="xs" mb="6">{{
            plan.isMonthly ? 'per month' : 'per year'
          }}</c-text>
          <div v-chakra mb="6">
            <label
              :for="`toggle-example-checked-${ix}`"
              class="toggle-button"
              v-if="!isFreePlan(plan)"
            >
              <input
                type="checkbox"
                :id="`toggle-example-checked-${ix}`"
                :checked="!plan.isMonthly"
                @change="plan.isMonthly = !plan.isMonthly"
              />
              <div class="toggle-bg"></div>
              <c-text mx="2" fontSize="xs" d="flex" color="#ef5323"
                >Yearly pricing /&nbsp;
                <c-text color="black">save 17%</c-text></c-text
              >
            </label>
          </div>
          <c-button
            cursor="pointer"
            variant-color="vc-orange"
            mb="6"
            :disabled="isCurrentPlan(plan) || isFreePlan(plan)"
            @click="subscribe(plan)"
          >
            Get started
          </c-button>
        </c-box>
        <c-box px="5" class="item-body" mt="8">
          <c-box d="flex" mb="3" v-for="(item, idx) in plan.items" :key="idx">
            <c-box h="2em" w="2em" mr="3">
              <svg
                fill="#ef5323"
                v-chakra="{
                  height: '1.5em',
                  width: '1em',
                }"
              >
                <use href="@/assets/icons/check-circle.svg#check"></use>
              </svg>
            </c-box>

            <div>
              <c-text fontSize=".9em">{{ item.title }}</c-text>
              <c-text
                fontSize="0.8em"
                color="gray.700"
                v-if="item.type === 'list'"
              >
                {{ item.subItems.join(', ') }}
              </c-text>
              <c-box v-if="item.type === 'bullets'">
                <div v-for="(subItem, indx) in item.subItems" :key="indx">
                  <c-text fontSize=".8em">{{ subItem.title }}</c-text>
                  <ul>
                    <li
                      v-for="(listItem, i) in subItem.items"
                      :key="i"
                      style="list-style: none"
                    >
                      <c-text fontSize=".8em">{{ listItem }}</c-text>
                    </li>
                  </ul>
                </div>
              </c-box>
            </div>
          </c-box>
        </c-box>
      </c-box>
    </c-box>

    <c-modal
      :is-open="showCurrentPlanDetails"
      :on-close="closeCurrentPlanDetails"
      :closeOnOverlayClick="false"
    >
      <c-modal-content ref="content">
        <c-modal-header>Your Current Plan</c-modal-header>
        <c-modal-close-button />
        <c-modal-body>
          <c-box py="3">
            <c-text
              d="flex"
              align-items="center"
              color="#ef5323"
              fontSize="xs"
              mb="2"
              >You're on {{ activeSub.name }} plan
            </c-text>
            <c-text mb="3" fontSize="md" fontWeight="bold">{{
              activeSub.description
            }}</c-text>

            <c-text
              mb="3"
              fontSize=".8em"
              color="gray.500"
              v-if="activeSub.name !== 'Free'"
            >
              Next payment is due on {{ activeSub.nextBillingDate }} <br />
              <b>
                ${{ activeSub.price }}
                USD
                {{ activeSub.frequency }}
              </b>
            </c-text>
          </c-box>
        </c-modal-body>
        <c-modal-footer>
          <c-button
            variant-color="vc-orange"
            mr="3"
            @click="onEndPlanClick"
            v-if="activeSub.name !== 'Free'"
          >
            End Plan
          </c-button>
        </c-modal-footer>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
  </div>
</template>

<script>
import { cancelSubscription } from '@/services/subscription';
import { mapState } from 'vuex';
import subscribtionMixin from '@/mixins/subscribtion.js';

export default {
  mixins: [subscribtionMixin],
  data() {
    return {
      activeSub: {},
      showCurrentPlanDetails: false,
      plans: [
        {
          title: 'Freemium',
          description: 'From ideation to startup',
          stage:
            'Ideation, MVP (Minimum variable product), Testing (Assumption validation)',
          price: 'Free',
          frequency: 'per month',
          isMonthly: true,
          action: '',
          image: 'bg-freemium',
          items: [
            {
              title: 'Companies foundation powered with AI',
              type: 'list',
              subItems: [
                'Vision',
                'Belief',
                'Values',
                'Culture',
                'Impact goals',
                'Skills and competence',
                'Team',
              ],
            },
            {
              title: 'Team and advisory board',
              type: 'list',
              subItems: ['Max 5 users'],
            },
            {
              title: 'Companies business model powered with AI',
              type: 'list',
              subItems: [
                'Problem',
                'solution',
                'Value proposition',
                'Target market customers',
              ],
            },
            {
              title: 'Market potential powered with AI',
              type: 'list',
              subItems: ['Market goals', 'Traction'],
            },
            {
              title: 'Overview',
              type: 'list',
              subItems: ['Business plan', 'Market plan'],
            },
            {
              title: 'Timeline',
              type: 'list',
              subItems: ['Market goals', 'milestones'],
            },
            {
              title:
                'Gamified Step by step plan tailored to your business stage',
              type: 'bullets',
              subItems: [
                { title: 'Stage 1', items: [] },
                { title: 'Stage 2', items: ['Foundation'] },
              ],
            },
            {
              title: 'Notes',
              type: 'list',
              subItems: [],
            },
            {
              title: 'Integration with slack',
              type: 'list',
              subItems: [],
            },
            {
              title: 'Link sharing option',
              type: 'list',
              subItems: [],
            },
            {
              title: '750,000 AI Points',
              type: '',
            },
            {
              title: 'AI autumated generated suggestion',
              type: 'list',
              subItems: [
                'Elavator pitch',
                'company name suggestion',
                'slogans suggestion',
                'Buzzwords suggestion',
                'Tweet suggestion',
                ' social hashtægs suggestion',
                'business report suggestions',
              ],
            },
          ],
          monthlyPrice: {
            price: 0,
          },
          yearlyPrice: {
            price: 0,
          },
        },
        {
          title: 'Pro',
          description: 'From Validation to Commercialization',
          stage:
            'Validation (Market research and product-market fit), Operational',
          price: '$15',
          frequency: 'per month',
          isMonthly: true,
          action: '',
          image: 'bg-premium',
          items: [
            {
              title: 'Everything in the freemium version ',
              type: 'list',
              subItems: [],
            },
            {
              title: 'Team and advisory board',
              type: 'list',
              subItems: ['Max 10 users'],
            },
            {
              title: 'Companies business model powered with AI',
              type: 'list',
              subItems: [
                'Everything in the freemium version + Channels',
                ' key resources',
                ' key partners',
                ' key activities',
                ' revenue',
                ' cost  ',
              ],
            },
            {
              title: 'Market potential powered with AI',
              type: 'list',
              subItems: [
                'Market goals',
                'Traction',
                'Go to market strategy',
                'TAM',
                'SAM',
                'SOM',
              ],
            },
            {
              title: 'Competition',
              type: 'list',
              subItems: [
                'Point of differentiation',
                'competition',
                'comparison',
              ],
            },
            {
              title: 'Risk Assessment',
              type: 'list',
              subItems: ['SWOT', 'PESTLE', 'Risk Analysis'],
            },
            {
              title: 'Overview',
              type: 'list',
              subItems: ['Business plan', 'Market plan'],
            },
            {
              title: 'Timeline',
              type: 'list',
              subItems: ['Market goals', 'Milestones', 'Scenarios', 'Phases'],
            },
            {
              title: 'Presentation',
              type: 'list',
              subItems: [
                'Pitch deck',
                'Foundation presentation',
                ' Market plan presentation',
              ],
            },
            {
              title:
                'Gamified Step by step plan tailored to your business stage',
              type: 'list',
              subItems: [],
            },
            {
              title: 'Notes',
              type: 'list',
              subItems: [],
            },
            {
              title: 'Integration with slack',
              type: 'list',
              subItems: [],
            },
            {
              title: 'Link sharing option',
              type: 'list',
              subItems: [],
            },
            {
              title: '750,000 AI Points',
              type: '',
            },
            {
              title: 'AI autumated generated suggestion',
              type: 'list',
              subItems: [
                'Elavator pitch',
                'company name suggestion',
                'slogans suggestion',
                'Buzzwords suggestion',
                'Tweet suggestion',
                ' social hashtægs suggestion',
                'business report suggestions',
              ],
            },
          ],
          monthlyPrice: {
            price: 0,
          },
          yearlyPrice: {
            price: 0,
          },
        },
        {
          title: 'Premium',
          description: 'From operational to scaling',
          stage: 'Stage: Scaling, fully established',
          price: '$30',
          frequency: 'per month',
          isMonthly: true,
          action: '',
          image: 'bg-enterprise',
          items: [
            {
              title: 'Everything in the Premium version ',
              type: 'list',
              subItems: [],
            },
            {
              title:
                'Dedicated 2 hours of professional guidance with a professional business advisor monthly.',
              type: 'list',
              subItems: [],
            },
          ],
          monthlyPrice: {
            price: 0,
          },
          yearlyPrice: {
            price: 0,
          },
        },
      ],
    };
  },
  mounted() {
    this.getPlans();
    this.activeSub = this.getActiveSubscription();
  },
  computed: {
    ...mapState('subscription', {
      storePlans: (state) => state.plans,
    }),
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
  },
  methods: {
    isCurrentPlan(plan) {
      return (
        this.activeCompany.activeSubscription.priceId ===
          plan.monthlyPrice?.stripeId ||
        this.activeCompany.activeSubscription.priceId ===
          plan.yearlyPrice.stripeId
      );
    },
    isFreePlan(plan) {
      return plan.title === 'Freemium';
    },
    subscribe(plan) {
      this.$router.push({
        name: 'ManageSubscription',
        query: {
          plan: plan.title,
          frequency: plan.isMonthly
            ? plan.monthlyPrice.frequency
            : plan.yearlyPrice.frequency,
        },
      });
    },
    getPlans() {
      const freePlan = this.storePlans.find((_item) => _item.name === 'Free');
      const premiumPlan = this.storePlans.find(
        (_item) => _item.name === 'Premium'
      );
      const proPlan = this.storePlans.find((_item) => _item.name === 'Pro');

      this.plans[0].monthlyPrice = freePlan?.plan_prices?.find(
        (price) => price.frequency === 'MONTHLY'
      );
      this.plans[0].yearlyPrice = freePlan?.plan_prices?.find(
        (price) => price.frequency === 'MONTHLY'
      );
      this.plans[1].monthlyPrice = proPlan?.plan_prices?.find(
        (price) => price.frequency === 'MONTHLY'
      );
      this.plans[1].yearlyPrice = proPlan?.plan_prices?.find(
        (price) => price.frequency === 'YEARLY'
      );
      this.plans[2].monthlyPrice = premiumPlan?.plan_prices?.find(
        (price) => price.frequency === 'MONTHLY'
      );
      this.plans[2].yearlyPrice = premiumPlan?.plan_prices?.find(
        (price) => price.frequency === 'YEARLY'
      );
    },
    closeCurrentPlanDetails() {
      this.showCurrentPlanDetails = false;
    },
    onEndPlanClick() {
      cancelSubscription();

      this.$toast({
        title: 'Success!!!',
        description: `Subscription renewal has been cancelled successfully`,
        status: 'success',
        position: 'top',
        duration: 3000,
      });
      // refresh page
      this.$router.go();
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle-button {
  @apply flex items-center cursor-pointer relative;

  .toggle-bg:after {
    content: '';
    @apply absolute top-0.5 left-0.5 bg-white border border-gray-300 rounded-full h-4 w-5 transition shadow-sm;
  }

  input:checked + .toggle-bg:after {
    transform: translateX(100%);
    @apply border-white;
  }

  input:checked + .toggle-bg {
    @apply bg-secondary border-secondary;
  }

  input {
    @apply sr-only;
  }

  div {
    @apply bg-gray-200 border-2 border-gray-200 h-4 w-10 rounded-full;
  }

  .discount {
    @apply ml-3 text-secondary text-sm font-medium;

    span {
      @apply text-gray-700 text-xs font-medium;
    }
  }
}

.subscription {
  .pricing {
    @apply flex flex-col justify-center my-10;
    min-height: 40rem;
    &__title {
      @apply text-primary font-bold text-3xl mb-6;
    }
    &__content {
      @apply flex flex-wrap w-full;
    }
    &__image,
    &__text {
      @apply my-4;
      flex-basis: 100%;
    }
    &__image {
      @apply mr-12;
      img {
        @apply w-full;
      }
    }
    &__text {
      @apply flex flex-col justify-center;
      &__title {
        @apply text-primary font-bold text-xl mb-4;
      }
      &__subtitle {
        @apply text-primary font-bold text-xl mb-4;
      }
      &__info {
        @apply text-lg;
      }
    }
  }
}
</style>
